.lno__ltr {
  text-align: left;
}
.lno__rtl {
  text-align: right;
}
.lno__paragraph {
  margin: 0;
  position: relative;
}
.lno__quote {
  margin: 0;
  margin-left: 20px;
  margin-bottom: 10px;
  font-size: 15px;
  color: rgb(101, 103, 107);
  border-left-color: rgb(206, 208, 212);
  border-left-width: 4px;
  border-left-style: solid;
  padding-left: 16px;
}
.lno__h1 {
  font-size: 24px;
  color: rgb(5, 5, 5);
  font-weight: 400;
  margin: 0;
}
.lno__h2 {
  font-size: 15px;
  color: rgb(101, 103, 107);
  font-weight: 700;
  margin: 0;
  text-transform: uppercase;
}
.lno__h3 {
  font-size: 12px;
  margin: 0;
  text-transform: uppercase;
}
.lno__indent {
  --lexical-indent-base-value: 40px;
}
.lno__textBold {
  font-weight: bold;
}
.lno__textItalic {
  font-style: italic;
}
.lno__textUnderline {
  text-decoration: underline;
}
.lno__textStrikethrough {
  text-decoration: line-through;
}
.lno__textUnderlineStrikethrough {
  text-decoration: underline line-through;
}
.lno__textSubscript {
  font-size: 0.8em;
  vertical-align: sub !important;
}
.lno__textSuperscript {
  font-size: 0.8em;
  vertical-align: super;
}
.lno__textCode {
  background-color: rgb(240, 242, 245);
  padding: 1px 0.25rem;
  font-family: Menlo, Consolas, Monaco, monospace;
  font-size: 94%;
}
.lno__hashtag {
  background-color: rgba(88, 144, 255, 0.15);
  border-bottom: 1px solid rgba(88, 144, 255, 0.3);
}
.Theme__documentFormInput {
  background-color: rgb(79 239 118);
  padding: 0.2rem;
}
.lno__link {
  color: rgb(33, 111, 219);
  text-decoration: none;
}
.lno__link:hover {
  text-decoration: underline;
  cursor: pointer;
}
.lno__code {
  background-color: rgb(240, 242, 245);
  font-family: Menlo, Consolas, Monaco, monospace;
  display: block;
  padding: 8px 8px 8px 52px;
  line-height: 1.53;
  font-size: 13px;
  margin: 0;
  margin-top: 8px;
  margin-bottom: 8px;
  overflow-x: auto;
  position: relative;
  tab-size: 2;
}
.lno__code:before {
  content: attr(data-gutter);
  position: absolute;
  background-color: #eee;
  left: 0;
  top: 0;
  border-right: 1px solid #ccc;
  padding: 8px;
  color: #777;
  white-space: pre-wrap;
  text-align: right;
  min-width: 25px;
}
.lno__table {
  border-collapse: collapse;
  border-spacing: 0;
  max-width: 100%;
  overflow-y: scroll;
  table-layout: fixed;
  width: calc(100% - 25px);
  margin: 30px 0;
}
.lno__tableSelected {
  outline: 2px solid rgb(60, 132, 244);
}
.lno__tableCell {
  border: 1px solid #bbb;
  min-width: 75px;
  vertical-align: top;
  text-align: start;
  padding: 6px 8px;
  position: relative;
  cursor: default;
  outline: none;
}
.lno__tableCellSortedIndicator {
  display: block;
  opacity: 0.5;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background-color: #999;
}
.lno__tableCellResizer {
  position: absolute;
  right: -4px;
  height: 100%;
  width: 8px;
  cursor: ew-resize;
  z-index: 10;
  top: 0;
}
.lno__tableCellHeader {
  background-color: #f2f3f5;
  text-align: start;
}
.lno__tableCellSelected {
  background-color: #c9dbf0;
}
.lno__tableCellPrimarySelected {
  border: 2px solid rgb(60, 132, 244);
  display: block;
  height: calc(100% - 2px);
  position: absolute;
  width: calc(100% - 2px);
  left: -1px;
  top: -1px;
  z-index: 2;
}
.lno__tableCellEditing {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
  border-radius: 3px;
}
.lno__tableAddColumns {
  position: absolute;
  top: 0;
  width: 20px;
  background-color: #eee;
  height: 100%;
  right: 0;
  animation: table-controls 0.2s ease;
  border: 0;
  cursor: pointer;
}
.lno__tableAddColumns:after {
  /* background-image: url("/images/icons/plus.svg"); */
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  display: block;
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.4;
}
.lno__tableAddColumns:hover {
  background-color: #c9dbf0;
}
.lno__tableAddRows {
  position: absolute;
  bottom: -25px;
  width: calc(100% - 25px);
  background-color: #eee;
  height: 20px;
  left: 0;
  animation: table-controls 0.2s ease;
  border: 0;
  cursor: pointer;
}
.lno__tableAddRows:after {
  /* background-image: url("/images/icons/plus.svg"); */
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  display: block;
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.4;
}
.lno__tableAddRows:hover {
  background-color: #c9dbf0;
}
@keyframes table-controls {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.lno__tableCellResizeRuler {
  display: block;
  position: absolute;
  width: 1px;
  background-color: rgb(60, 132, 244);
  height: 100%;
  top: 0;
}
.lno__tableCellActionButtonContainer {
  display: block;
  right: 5px;
  top: 6px;
  position: absolute;
  z-index: 4;
  width: 20px;
  height: 20px;
}
.lno__tableCellActionButton {
  background-color: #eee;
  display: block;
  border: 0;
  border-radius: 20px;
  width: 20px;
  height: 20px;
  color: #222;
  cursor: pointer;
}
.lno__tableCellActionButton:hover {
  background-color: #ddd;
}
.lno__characterLimit {
  display: inline;
  background-color: #ffbbbb !important;
}
.lno__ol1 {
  padding: 0;
  margin: 0;
  list-style-position: inside;
}
.lno__ol2 {
  padding: 0;
  margin: 0;
  list-style-type: upper-alpha;
  list-style-position: inside;
}
.lno__ol3 {
  padding: 0;
  margin: 0;
  list-style-type: lower-alpha;
  list-style-position: inside;
}
.lno__ol4 {
  padding: 0;
  margin: 0;
  list-style-type: upper-roman;
  list-style-position: inside;
}
.lno__ol5 {
  padding: 0;
  margin: 0;
  list-style-type: lower-roman;
  list-style-position: inside;
}
.lno__ul {
  padding: 0;
  margin: 0;
  list-style-position: inside;
}
.lno__listItem {
  margin: 0 32px;
}
.lno__listItemChecked,
.lno__listItemUnchecked {
  position: relative;
  margin-left: 8px;
  margin-right: 8px;
  padding-left: 24px;
  padding-right: 24px;
  list-style-type: none;
  outline: none;
}
.lno__listItemChecked {
  text-decoration: line-through;
}
.lno__listItemUnchecked:before,
.lno__listItemChecked:before {
  content: "";
  width: 16px;
  height: 16px;
  top: 2px;
  left: 0;
  cursor: pointer;
  display: block;
  background-size: cover;
  position: absolute;
}
.lno__listItemUnchecked[dir="rtl"]:before,
.lno__listItemChecked[dir="rtl"]:before {
  left: auto;
  right: 0;
}
.lno__listItemUnchecked:focus:before,
.lno__listItemChecked:focus:before {
  box-shadow: 0 0 0 2px #a6cdfe;
  border-radius: 2px;
}
.lno__listItemUnchecked:before {
  border: 1px solid #999;
  border-radius: 2px;
}
.lno__listItemChecked:before {
  border: 1px solid rgb(61, 135, 245);
  border-radius: 2px;
  background-color: #3d87f5;
  background-repeat: no-repeat;
}
.lno__listItemChecked:after {
  content: "";
  cursor: pointer;
  border-color: #fff;
  border-style: solid;
  position: absolute;
  display: block;
  top: 6px;
  width: 3px;
  left: 7px;
  right: 7px;
  height: 6px;
  transform: rotate(45deg);
  border-width: 0 2px 2px 0;
}
.lno__nestedListItem {
  list-style-type: none;
}
.lno__nestedListItem:before,
.lno__nestedListItem:after {
  display: none;
}
.lno__tokenComment {
  color: slategray;
}
.lno__tokenPunctuation {
  color: #999;
}
.lno__tokenProperty {
  color: #905;
}
.lno__tokenSelector {
  color: #690;
}
.lno__tokenOperator {
  color: #9a6e3a;
}
.lno__tokenAttr {
  color: #07a;
}
.lno__tokenVariable {
  color: #e90;
}
.lno__tokenFunction {
  color: #dd4a68;
}
.lno__mark {
  background: rgba(255, 212, 0, 0.14);
  border-bottom: 2px solid rgba(255, 212, 0, 0.3);
  padding-bottom: 2px;
}
.lno__markOverlap {
  background: rgba(255, 212, 0, 0.3);
  border-bottom: 2px solid rgba(255, 212, 0, 0.7);
}
.lno__mark.selected {
  background: rgba(255, 212, 0, 0.5);
  border-bottom: 2px solid rgba(255, 212, 0, 1);
}
.lno__markOverlap.selected {
  background: rgba(255, 212, 0, 0.7);
  border-bottom: 2px solid rgba(255, 212, 0, 0.7);
}
.lno__embedBlock {
  user-select: none;
}
.lno__embedBlockFocus {
  outline: 2px solid rgb(60, 132, 244);
}
