.root {
  display: flex;
}

.fieldsWithGap {
  display: flex;
  flex-wrap: wrap;
  & > * {
    margin: 1%;
  }
}
