.root {
  display: grid;
  grid-template-columns: 1fr 6fr;
  padding: 1.25rem 1.25rem;

  .head {
    display: flex;
    width: 100%;
    justify-content: space-between;
    min-width: 12rem;

    .sidebarHide {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .drawerToggle {
      display: flex;
      align-self: center;
    }
  }

  .tail {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 2rem;
    width: 100%;
  }

  a {
    font-size: 0.9rem;
    color: rgb(0, 0, 0);
    text-decoration: none;
  }
}
