@keyframes slidein {
  from {
    transform: translateX(100px);
  }

  to {
    transform: translateX(0);
  }
}

@keyframes slideout {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(120px);
  }
}

.root {
  display: initial;

  .formNode {
    position: relative;
    overflow: hidden;
    transition: 0.15s all;

    &:hover {
      .pasteFormNode {
        animation-name: slidein;
        animation-duration: 0.24s;
        animation-fill-mode: forwards;
      }

      .deleteFormNode {
        animation-name: slidein;
        animation-duration: 0.15s;
        animation-fill-mode: forwards;
      }
    }
  }
}

.formNodeActions {
  display: flex;
  position: absolute;
  top: 0;
  right: 0px;
  height: 100%;
}

.overlayButton {
  display: flex;
  height: 100%;
  transform: translateX(120px);
  animation-name: slideout;
  animation-duration: 0.15s;
  background: rgb(255, 60, 60);
  justify-content: center;
  align-items: center;
  color: white;
  padding: 0 1rem;
  font-weight: 500;
  box-shadow: -5px 0 10px rgba(0, 0, 0, 0.085);
  cursor: pointer;
  animation-iteration-count: 1;
  transition: 0.3s all;
}

.deleteFormNode {
  composes: overlayButton;
  right: 0;
}

.pasteFormNode {
  composes: overlayButton;
  right: 20px;
  background: rgb(92, 161, 114);
  z-index: 2;
}
