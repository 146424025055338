.gridRoot {
  display: grid;
  grid-template-columns: 1fr 9fr;
  background: rgb(247, 247, 247);
  min-height: 100vh;

  @media (max-width: 1024px) {
    grid-template-columns: 1fr;
  }
}

.children {
  padding: 1rem;
}
