.root {
  a {
    font-size: 0.9rem;
    color: black;
    text-decoration: none;
    height: 100%;
    width: 100%;
  }

  @media (max-width: 1024px) {
    display: none;
  }
}
